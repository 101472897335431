/* -------------------------------------------
    Spacing
---------------------------------------------- */

@for $i from 0 through 50 {
  .mt-#{$i} {
    margin-top: #{$i * 0.5}rem !important;
  }

  .mb-#{$i} {
    margin-bottom: #{$i * 0.5}rem !important;
  }

  .pt-#{$i} {
    padding-top: #{$i * 0.5}rem !important;
  }

  .pb-#{$i} {
    padding-bottom: #{$i * 0.5}rem !important;
  }
}

@for $i from 0 through 50 {
  .ml-#{$i} {
    margin-left: #{$i * 0.5}rem !important;
  }

  .mr-#{$i} {
    margin-right: #{$i * 0.5}rem !important;
  }

  .pl-#{$i} {
    padding-left: #{$i * 0.5}rem !important;
  }

  .pr-#{$i} {
    padding-right: #{$i * 0.5}rem !important;
  }

  .gap-#{$i} {
    gap: #{$i}px !important;
  }
  .gap-x-#{$i} {
    column-gap: #{$i}px !important;
  }
  .gap-y-#{$i} {
    row-gap: #{$i}px !important;
  }
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@for $i from 0 through 50 {
  .h-#{$i} {
    height: #{$i * 0.5}rem !important;
  }
}
@for $i from 0 through 50 {
  .w-#{$i} {
    width: #{$i * 0.5}rem !important;
  }
}

@include mq("sm") {
  @for $i from 0 through 50 {
    .mt-sm-#{$i} {
      margin-top: #{$i * 0.5}rem !important;
    }
  }

  @for $i from 0 through 50 {
    .mb-sm-#{$i} {
      margin-bottom: #{$i * 0.5}rem !important;
    }
  }

  @for $i from 0 through 50 {
    .mr-sm-#{$i} {
      margin-right: #{$i * 0.5}rem !important;
    }
  }

  @for $i from 0 through 50 {
    .ml-sm-#{$i} {
      margin-left: #{$i * 0.5}rem !important;
    }
  }

  @for $i from 0 through 50 {
    .pt-sm-#{$i} {
      padding-top: #{$i * 0.5}rem !important;
    }
  }

  @for $i from 0 through 50 {
    .pr-sm-#{$i} {
      padding-right: #{$i * 0.5}rem !important;
    }
  }

  @for $i from 0 through 50 {
    .pl-sm-#{$i} {
      padding-left: #{$i * 0.5}rem !important;
    }
  }

  @for $i from 0 through 50 {
    .pb-sm-#{$i} {
      padding-bottom: #{$i * 0.5}rem !important;
    }
  }
}

@include mq("md") {
  @for $i from 0 through 50 {
    .mt-md-#{$i} {
      margin-top: #{$i * 0.5}rem !important;
    }
  }

  @for $i from 0 through 50 {
    .mb-md-#{$i} {
      margin-bottom: #{$i * 0.5}rem !important;
    }
  }

  @for $i from 0 through 50 {
    .pt-md-#{$i} {
      padding-top: #{$i * 0.5}rem !important;
    }
  }

  @for $i from 0 through 50 {
    .pb-md-#{$i} {
      padding-bottom: #{$i * 0.5}rem !important;
    }
  }
}

@include mq("lg") {
  @for $i from 0 through 50 {
    .mt-lg-#{$i} {
      margin-top: #{$i * 0.5}rem !important;
    }
  }

  @for $i from 0 through 50 {
    .mb-lg-#{$i} {
      margin-bottom: #{$i * 0.5}rem !important;
    }
  }

  @for $i from 0 through 50 {
    .pt-lg-#{$i} {
      padding-top: #{$i * 0.5}rem !important;
    }
  }

  @for $i from 0 through 50 {
    .pb-lg-#{$i} {
      padding-bottom: #{$i * 0.5}rem !important;
    }
  }

  @for $i from 0 through 50 {
    .ml-lg-#{$i} {
      margin-left: #{$i * 0.5}rem !important;
    }

    .mr-lg-#{$i} {
      margin-right: #{$i * 0.5}rem !important;
    }

    .pl-lg-#{$i} {
      padding-left: #{$i * 0.5}rem !important;
    }

    .pr-lg-#{$i} {
      padding-right: #{$i * 0.5}rem !important;
    }
  }
}

@include mq("xl") {
  @for $i from 0 through 10 {
    .mt-xl-#{$i} {
      margin-top: #{$i * 0.5}rem !important;
    }
  }

  @for $i from 0 through 50 {
    .mb-xl-#{$i} {
      margin-bottom: #{$i * 0.5}rem !important;
    }
  }

  @for $i from 0 through 50 {
    .ml-xl-#{$i} {
      margin-left: #{$i * 0.5}rem !important;
    }
  }

  @for $i from 0 through 50 {
    .mr-xl-#{$i} {
      margin-right: #{$i * 0.5}rem !important;
    }
  }
}
@include mq(lg) {
  .ml-lg-auto {
    margin-left: auto !important;
  }

  .mr-lg-auto {
    margin-right: auto !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
