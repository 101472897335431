/* -------------------------------------------
   Titles
        - Default
        - Simple
        - Line
        - Underline
        - Icon
        - Link
---------------------------------------------- */

// Variables
@include set-default(
  (
    base: (
      title: (
        margin-bottom: 2.6rem,
        text-transform: capitalize,
        font-size: 2.4rem,
        font-weight: 700,
        line-height: 1,
        letter-spacing: 0.1rem,
        color: inherit,
        desc: (
          margin-bottom: 2.3rem,
          text-transform: false,
          font-family: false,
          font-size: 1.4rem,
          font-weight: false,
          line-height: 1.71,
          letter-spacing: false,
          color: #999,
        ),
        border: (
          color: $border-color,
          _active-color: $primary-color,
          _height: 2px,
          line-height: 2.28,
        ),
      ),
      wrapper: (
        title: (
          margin-bottom: 0.5rem,
        ),
      ),
    ),
  )
);

.title {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  text-align: center;
  @include print_css(base, title);
  &.title-center {
    justify-content: center;
  }
}

.title-wrapper {
  .title {
    @include css(margin-bottom, base, wrapper, title, margin-bottom);
  }
  p {
    @include print_css(base, title, desc);
  }
}

// Simple
.title-simple {
  display: block;

  &::before,
  &::after {
    content: none;
  }
}

.capitalize-title {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  text-align: center;
  text-transform: uppercase !important;
  @include print_css(base, title);
}
