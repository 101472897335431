/* -------------------------------------------
    Banner
        - Default
        - Video Banner
        - Hover Effect - Button Hide
---------------------------------------------- */

// Default
.banner {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-size: 1rem;
  figure img {
    display: block;
    width: 100%;
    height: auto;
  }

  &.inner-banner {
    &::before {
      content: "";
      position: absolute;
      left: 1rem;
      right: 1rem;
      top: 1rem;
      bottom: 1rem;
      border: 2px solid rgba(225, 225, 225, 0.3);
      z-index: 1;
    }
  }
}
.banner-fixed {
  > .container,
  > .container-fluid,
  > .banner-content {
    position: absolute;
    z-index: 1;
  }

  > .container,
  > .container-fluid {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &.content-middle .banner-content {
    top: 50%;
    transform: translateY(-50%);
  }

  &.content-center {
    .banner-content {
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
    }
    &.content-middle .banner-content {
      transform: translate(-50%, -50%);
    }
  }
  .intro-slider-link {
    .intro-slider-figure {
      img {
        height: auto !important;
        object-fit: cover;
      }
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .intro-slider {
    .banner img {
      height: 100%;
      width: 100%;
    }
  }
}

@include mq(sm, max) {
  .intro-slider-container {
    max-height: 310px;
  }
}
