/* -------------------------------------------
    Icon Box
---------------------------------------------- */

.icon-box {
  .icon-box-title {
    margin: 0 0 0.7rem;
    text-transform: uppercase;
    font-size: 1.5rem;
    letter-spacing: 0.025em;
    line-height: 1.6;
  }
  p {
    margin: 0;
    font-size: 1.4rem;
    line-height: 1.72;
    letter-spacing: 0;
    color: #666;
  }
  i::before {
    margin: 0;
  }

  .icon-box-content {
    text-align: center;
  }
}

.icon-solid {
  svg {
    width: 5.4rem;
    height: 5.4rem;
    fill: $primary-color;
  }
}
