.category-section {
  &.masonry-section {
    .grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, 50%);
      grid-template-rows: repeat(auto-fill, calc(100% / 3));
      grid-auto-rows: auto;

      .height-x2 {
        grid-column-end: span 1;
        grid-row-end: span 2;
      }
    }
  }

  &.badge-section {
    .grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, 50%);
      grid-template-rows: repeat(auto-fill, 50%);
      grid-auto-rows: auto;

      .height-x2 {
        grid-column-end: span 1;
        grid-row-end: span 2;
      }
    }
  }
}

.posts.grid:not(.masonry) {
  display: grid;

  .info-box {
    grid-column-end: span 3;
  }

  &.grid-2cols {
    grid-template-columns: repeat(auto-fill, 50%);
  }

  &.grid-3cols {
    grid-template-columns: repeat(auto-fill, calc(100% / 3));
  }

  &.grid-4cols {
    grid-template-columns: repeat(auto-fill, 25%);
  }
}

@include mq(xl, max) {
  .posts.grid:not(.masonry) {
    &.grid-4cols {
      grid-template-columns: repeat(auto-fill, calc(100% / 3));
    }
  }
}

@include mq(lg, max) {
  .posts.grid:not(.masonry) {
    &.grid-3cols,
    &.grid-4cols {
      grid-template-columns: repeat(auto-fill, 50%);
    }
  }
}

@include mq(md, max) {
  .category-section {
    &.masonry-section {
      .grid {
        grid-template-rows: repeat(auto-fill, calc(100% / 6));

        .height-x2 {
          grid-column-end: span 2;
          grid-row-end: span 2;
        }

        .height-x1 {
          grid-column-end: span 2;
        }
      }
    }

    &.badge-section {
      .grid {
        grid-template-rows: repeat(auto-fill, 25%);

        .height-x2 {
          grid-column-end: span 2;
          grid-row-end: span 2;
        }

        .height-x1 {
          grid-column-end: span 2;
        }
      }
    }
  }
}

@include mq(sm, max) {
  .posts.grid:not(.masonry) {
    &.grid-2cols,
    &.grid-3cols,
    &.grid-4cols {
      grid-template-columns: repeat(auto-fill, 100%);
    }
  }
}
