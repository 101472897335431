/* -------------------------------------------
    Error 404 Page
---------------------------------------------- */
.error-section {
    background: #f7f7f7;
    height: 694px;

    h1 {
        font-size: 3.4rem;
    }

    .btn {
        padding: 1em 3.6em;
    }
}