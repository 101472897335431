/* -------------------------------------------
    Layout
---------------------------------------------- */
.container,
.container-fluid {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}

@include mq(xs, max) {
  .container,
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.container-fluid {
  @include css(max-width, base, _container-fluid-width);
}
.container {
  @include css(max-width, base, _container-width);
}

.divider {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.5rem;
  height: 2.5rem;
  width: 1px;
  background-color: $border-color;
}

.rowss:first-child {
  .col-lg-6 {
    width: 50%;
    flex: none;
  }

  .col-lg-4 {
    width: 33.3333%;
    flex: none;
    padding-right: 12px;
    padding-left: 0px;
  }
}

.lh-111 {
  line-height: 1.8rem;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.rowss {
  display: flex;
  flex-wrap: wrap;

  .col-6 {
    width: 50%;
    flex: none;
    padding-right: 8px;
    padding-left: 8px;
    margin-top: 18px;

    @media (max-width: 767px) {
      padding-right: 4px;
      padding-left: 4px;
      margin-top: 8px;
    }
  }

  .col-6.mobile-left {
    @media (max-width: 767px) {
      padding-left: 0;
    }
  }
  .col-6.mobile-right {
    @media (max-width: 767px) {
      padding-right: 0;
    }
  }

  .col-12 {
    width: 100%;
    flex: none;
  }

  @media (min-width: 992px) {
    .col-lg-6 {
      width: 50%;
      flex: none;
    }

    .col-lg-4 {
      width: 33.3333%;
      flex: none;
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  @media (min-width: 700px) {
    .col-md-6 {
      width: 50%;
      flex: none;
    }
  }
}

.coupon-discount-bar {
  background-color: #17b31b;
  justify-content: center;
  z-index: 1000;
  width: 100%;

  @media (min-width: 575px) {
    max-width: 50rem !important;
  }
}

.animate {
  animation-name: slideIn;
  animation-duration: 1s;
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

// @media (min-width: 480px) {
//   .coupon-discount-bar {
//     bottom: 72px !important;
//   }
// }

.coupon-discount {
  .coupon-discount-text {
    letter-spacing: 1.5px;
    font-weight: 400;
    color: white;
    text-align: center;
    font-size: 12px !important;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
}

.modal-title {
  color: #8d8d8d;
  text-align: center;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: 450px) {
    font-size: 16px;
  }
}

.modal-amount {
  color: #000;
  text-align: center;
  font-family: Manrope;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: 450px) {
    font-size: 20px;
  }
}

.close-icon {
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  margin: 5px;
  cursor: pointer;
}
