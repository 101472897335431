// @import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

.blog {
  font-family: Outfit, sans-serif;

  * {
    font-family: Outfit, sans-serif;

    .wp-block-heading {
      display: inline-block;
    }
  }

  .blog-content {
    a {
      color: #eeb63d;
      text-decoration: none;
      transition: color 0.3s;
      &:hover {
        color: #eeb63d;
        text-decoration: underline;
      }
    }

    .wp-block-pullquote {
      padding: 0;
      margin: 0;
      border: 0;
      blockquote {
        text-align: start;
        margin: 2.4rem 0;

        p {
          margin: 0;
        }
      }
    }

    ol {
      list-style-type: decimal;
      padding-left: 2rem;
    }

    blockquote {
      font-style: italic;
      padding: 1.6rem;
      background: #f9f9f9;
      border-left: 4px solid #eeb63d;
      padding: 1rem;
      text-align: start;
      margin: 2.4rem 0;

      p {
        margin: 0;
      }
    }
  }
}
.blog-font-size-convention {
  h1 {
    font-size: 35px !important;
  }
  h2 {
    font-size: 25px !important;
  }
  h3 {
    font-size: 20px !important;
  }
  h4 {
    font-size: 19px !important;
  }
  h5 {
    font-size: 18px !important;
  }
  p {
    font-size: 17px;
  }
  li {
    font-size: 17px;
  }
  blockquote {
    font-size: 19px !important;
  }
  .author-bio {
    color: #666;
    font-size: 14px;
  }
}
