/* -------------------------------------------
    Quick view
---------------------------------------------- */

.large-image img {
  max-width: none;
}

.quickview-modal {
  .btn-close {
    transform: rotateZ(45deg);
    transition: transform 0.3s;
    width: 18px;
    height: 18px;
    opacity: 1;

    &:hover {
      transform: rotateZ(135deg) scale(1.5);
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      top: calc(50% - 1px);
      left: 0;
      background: #fff;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      background: #fff;
      width: 2px;
      height: 100%;
      top: 0;
      left: calc(50% - 1px);
    }
  }
}

.product-gallery-details {
  &::-webkit-scrollbar {
    height: 7px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    margin-right: 2px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-image: 10px;
    margin: 8px;
    width: 100%;
  }
}

.product-popup {
  .product-details {
    padding: 0;
  }
}

.quickview-modal {
  max-width: 105.8rem;
  padding: 3rem 2rem 3rem;
  width: 100%;
  background: #fff;
  border-radius: 1rem;
}

.ReactModal__Overlay {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 4rem 3rem 2rem;
}

@include mq(md) {
  .quickview-modal {
    .skeleton-body .col-md-6 {
      overflow: hidden;

      .skel-pro-summary {
        position: absolute;
      }
    }
  }
}

@include mq("md", "max") {
  .quickview-modal {
    .product-details {
      height: auto !important;
    }
  }

  .ReactModal__Overlay {
    display: block;
  }
}

@include mq(sm, "max") {
  .coupon-modal-container {
    padding-right: 0;
    padding-left: 0;
    .ReactModal__Content {
      position: relative;
      width: 90vw;
      max-width: 38rem;
    }
  }
}

@include mq(xs, "max") {
  .coupon-modal-container {
    padding: 4rem 2rem 2rem 2rem !important;
  }
  .ReactModal__Overlay {
    padding: 4rem 1rem 2rem;

    .quickview-modal {
      // margin: 0;
      padding: 2rem 1rem;

      .skeleton-body {
        padding: 0;
      }
    }
  }
}

@supports (-ms-ime-align: auto) {
  .ReactModal__Overlay {
    display: flex !important;
  }

  .product-popup {
    min-width: 20rem !important;
    top: auto !important;
    transform: none !important;
  }

  .newsletter-popup-container {
    top: 0 !important;
    margin: auto;
    transform: translate3d(0px, 0, 1000px) perspective(600px) rotateX(30deg);

    &.opened {
      transform: translate3d(0px, 0, 1000px) perspective(600px);
    }
  }
}
