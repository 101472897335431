/* -------------------------------------------
        Base
    ---------------------------------------------- */

// Variables
@include set-default(
  (
    base: (
      // max-width of '.container'
      _container-width: 1220px,
      // max-width of '.container-fluid'
      _container-fluid-width: 1820px,
      // grid spaces
      _gutter-lg: 15px,
      _gutter-md: 10px,
      _gutter-sm: 5px,
      _gutter-xs: 1px,
      // background of grey section
      _grey-section-bg: #f6f7f9,
      // Body
      body:
        (
          font-family: $font-family,
          font-size: 1.4rem,
          line-height: 1.6,
          color: $body-color
        ),
      page-wrapper: (margin-left: false, margin-right: false),
      // ScrollTop
      scroll-top: (background-color: #fff),
    ),
  )
);

*,
::after,
::before {
  touch-action: pan-x pan-y !important;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  font-size-adjust: 100%;
  font-weight: 400;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  // overflow-x: hidden;
  @include print_css(base, body);
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  touch-action: pan-x pan-y;
  letter-spacing: 0.05rem !important;
}

main {
  display: block;
  position: relative;
}

body:not(.loaded) * {
  transition: all 0s !important;
}

.spin-loader {
  width: 17px;
  height: 17px;
  -webkit-animation: spin 650ms infinite linear;
  animation: spin 650ms infinite linear;
  border-radius: 75%;
  border: 2px solid #fff !important;
  border-bottom-color: transparent !important;
  z-index: 9999;
}

.loaded .page-wrapper {
  opacity: 1;
}

.App {
  text-align: center;
  margin-top: 50px;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.page-wrapper {
  opacity: 0;
  position: relative;
  transition: margin 0.4s, opacity 0.5s;
  @include print_css(base, page-wrapper);
  background-color: $background-color-1;
  min-height: 100vh;
}

.link-text {
  color: cadetblue;
}

.page-content {
  color: $text-color-1;
}

table {
  width: 100%;
  border-collapse: collapse;

  td,
  th {
    padding: 0;
  }
}

.section {
  padding: 7rem 0;
}

.grey-section {
  @include css(background, base, _grey-section-bg);
}

.background-section {
  background-repeat: no-repeat;
}

.parallax {
  background-color: $background-color-2;
}

ul {
  padding-left: 1.5em;
}

.menu,
.menu ul,
.mobile-menu,
.mobile-menu ul,
.nav,
.nav ul,
.widget-body,
.widget-body ul,
.list,
.breadcrumb,
.filter-items,
.select-menu > ul,
.dropdown-box,
.pagination,
.nav-filters,
.category ul,
.comments ul,
.product-nav,
.product-tabs > div ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s;
  // &:hover {
  //     opacity: 70%;
  // }
}

.mobile-menu {
  a {
    &:hover {
      opacity: 70%;
    }
  }
}

:focus {
  outline: 0;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

hr {
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid $border-color-light;
}

input {
  -webkit-appearance: none;
  font-size: 16px !important;
  border: 0;
  padding: 0;
  font-family: $font-family;

  &:focus {
    outline: 0;
  }
}

i {
  font-style: normal;
}

button {
  &:focus {
    outline: none;
  }
}

.scrollable,
.sidebar-content {
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    height: 7px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    margin-right: 2px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
  }
}

.scrollable-light::-webkit-scrollbar-thumb {
  background: rgba(#fff, 0.2);
}

// Overlay Animation
@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.2);
  }
}

@keyframes rotatedelay {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes reveal-1 {
  0% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }

  20% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(55%, 55%, 0);
    border-radius: 0;
  }

  80% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(55%, 55%, 0);
    border-radius: 0;
  }

  100% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }
}

@keyframes reveal-2 {
  0% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }

  20% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(55%, -55%, 0);
    border-radius: 0;
  }

  80% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(55%, -55%, 0);
    border-radius: 0;
  }

  100% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }
}

@keyframes reveal-3 {
  0% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }

  20% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(-55%, -55%, 0);
    border-radius: 0;
  }

  80% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(-55%, -55%, 0);
    border-radius: 0;
  }

  100% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }
}

@keyframes reveal-4 {
  0% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }

  20% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(-55%, 55%, 0);
    border-radius: 0;
  }

  80% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(-55%, 55%, 0);
    border-radius: 0;
  }

  100% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }
}

.bounce-loader {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  //margin: -9px 0 0 -35px;
  transition: all 0.2s;
  text-align: center;
  z-index: 10000;

  //animation: 2s ease-in-out 0s normal both infinite rotatedelay;
  .bounce1,
  .bounce2,
  .bounce3,
  .bounce4 {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    margin-bottom: 5px;
    // border-radius: 100%;
    background-color: $primary-color;
    //box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
    //animation: 1s ease-in-out 0s normal both infinite bouncedelay;
  }

  .bounce1 {
    animation: 2s infinite reveal-1;
  }

  .bounce2 {
    animation: 2s infinite reveal-2;
  }

  .bounce3 {
    animation: 2s infinite reveal-3;
  }

  .bounce4 {
    animation: 2s infinite reveal-4;
  }

  .bounce3 {
    border: 3px solid $primary-color;
    background-color: transparent;
  }
}

// Animation
.appear-animate {
  transform: translate3d(0, 0, 0) scale(1);
  will-change: transform, filter, opacity;
}

.fade {
  opacity: 0;
  transition: opacity 0.5s;

  &.in {
    opacity: 1;
  }
}

// ScrollTop
.scroll-top {
  position: fixed;
  text-align: center;
  bottom: 30px;
  left: auto;
  right: 30px;
  width: 60px;
  height: 60px;
  font-size: 27px;
  opacity: 0;
  visibility: hidden;
  transition: transform 0.3s, visibility 0.3s, opacity 0.3s;
  color: #222;
  transform: translateY(40px);
  border-radius: 3px;
  z-index: 999;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  line-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include print_css(base, scroll-top);

  &:hover {
    color: #222;
  }

  i {
    font-weight: 900;
    line-height: inherit;
  }
}
.woot--bubble-holder-hidden {
  visibility: hidden !important;
}

// d-loading
.d-loading {
  display: block;
  position: absolute;
  top: 100%;
  left: calc(50% - 17px);
  width: 34px;
  height: 34px;
  border: 2px solid transparent;
  border-top-color: rgb(0, 0, 0);
  border-radius: 50%;
  animation: spin 0.75s infinite linear;

  &:before {
    content: "";
    top: -2px;
    left: -2px;
    position: absolute;
    width: inherit;
    height: inherit;
    border: inherit;
    border-radius: inherit;
    animation: spin 1.5s infinite ease;
  }
}

// toaster
.Toastify {
  .Toastify__toast-body {
    padding: 0 !important;
  }

  .Toastify__toast {
    min-height: fit-content !important;
  }
}

// body {
//     transition: opacity .1s;
//     opacity: 0;

//     &.loaded {
//         opacity: 1;
//     }
// }

@include mq(sm, max) {
  main {
    // margin-top: 60px;

    // &.searchBar {
    //   margin-top: 130px !important;
    // }
  }
}

@include mq(lg, max) {
  #__next {
    overflow: hidden;
  }
}

@include mq(md) {
  .scroll-top.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}

// Sticky Content Animation
@keyframes fixedTop {
  0% {
    margin-top: -60px;
  }

  100% {
    margin-top: 0;
  }
}

@keyframes fixedBottom {
  from {
    transform: translateY(100%);
    transform-origin: center top 0px;
  }

  to {
    transform: translateY(0);
  }
}

// Sticky Content(new)
.sticky-content {
  &.fix-top {
    top: 0;
  }

  &.fix-bottom {
    bottom: 0;
  }

  &.fixed {
    &.fix-top {
      animation: fixedTop 0.4s;
    }

    &.fix-bottom {
      animation: fixedBottom 0.4s;
      transition: margin-bottom 0.4s;
    }

    position: fixed;
    left: 0;
    right: 0;
    opacity: 1;
    // transform: translateY(0);
    background: #fff;
    z-index: 1051;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  }
}

// Load more icon animation (new)
.loading:not(.load-more-overlay),
.load-more-overlay.loading::after {
  animation: spin 650ms infinite linear;
  border: 2px solid transparent;
  border-radius: 32px;
  border-top: 2px solid rgba(0, 0, 0, 0.4) !important;
  border-right: 2px solid rgba(0, 0, 0, 0.4) !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.4) !important;
  content: "";
  display: block;
  height: 21px;
  top: 50%;
  margin-top: -11px;
  left: 50%;
  margin-left: -10px;
  right: auto;
  position: absolute;
  width: 21px;
}

.load-more-overlay {
  position: relative;

  &.loading::after {
    content: "";
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    opacity: 0.8;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.riode-rounded-skin {
  .btn,
  .post-calendar,
  .product-hide-details .btn-product,
  .product-hide-details .btn-product-icon,
  .post-single > .post-wrap img,
  .post-single .post-author-detail,
  .post-media,
  .post-framed,
  .minipopup-box,
  .product-category,
  .product-category .category-content,
  .product-with-qty .quantity button,
  .product-wrapper .banner,
  .vendor-widget .vendor-logo,
  .vendor-widget .vendor-product > figure,
  .vendor-widget .vendor-banner {
    border-radius: 3px;
    overflow: hidden;
  }

  .login-popup {
    border-radius: 3px;
  }

  .form-row [type="text"],
  .form-row [type="tel"],
  .form-row [type="password"],
  .form-row [type="email"],
  .form-row textarea {
    border-radius: 3px;
  }

  .btn-link,
  .form-coupon .input-text {
    border-radius: 0;
    overflow: visible;
  }

  .post.post-mask.gradient::before {
    border-radius: 0 0 3px 3px;
    overflow: hidden;
  }

  .mfp-product .product,
  .product-category.cat-type-default {
    border-radius: 10px;
  }
}

.overflow-ellipsis {
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
}

.overflow-ellipsis2 {
  -webkit-box-orient: vertical;
  overflow: hidden;
}

//border
.border-regular {
  border: 1px solid #e1e1e1;
}

.border-cricle {
  border: 1px solid #e1e1e1;
  border-radius: 50%;
}

.text-align-start {
  text-align: start !important;
}

.cart-product-size {
  font-size: 14px !important;
  font-weight: bolder !important;

  @media (max-width: 500px) {
    font-size: 12px !important;
  }
}

.min-order-msg {
  width: 18rem;
}
.min-order-msg-mobile {
  max-width: 20rem;
}

.address-button-styling {
  background: white !important;
  border: 0.5px solid #6f4632 !important;
  color: #6f4632 !important;
  border-radius: 0.5rem;
}
// Bottom drawer styling starts here
.drawer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: -100%;
  width: 100%;
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transition: bottom 0.3s ease;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999999999;

  .drawer-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .drawer-header {
      font-size: 18px;
    }

    .close-btn {
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .mobile-number-input {
    font-size: 14px !important;
  }
  .drawer-otp-input {
    max-width: 4.5rem;
    height: 4rem;
    text-align: center; /* Center text horizontally */
    font-size: 1.5rem; /* Adjust the font size as needed */
    line-height: 4rem; /* Center text vertically */
    border: 1px solid #ccc; /* Optional: Add a border for better visibility */
    border-radius: 0.25rem; /* Optional: Add rounded corners */
  }
  .drawer-content {
    width: 100%;
    padding: 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1.5rem;
  }
  .mobile-drawer-address-btn {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}

.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 1000; /* Ensure it's above other content */
}

.drawer.open {
  bottom: 0;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.drawer-body {
  flex: 1;
  width: 100%;
}

.close-btn:hover {
  color: red;
}
// Bottom drawer styling ends here

// FOMO product styling starts here
.fomo-wrapper.hidden {
  transform: translateY(100%); /* Slide out to the bottom */
  visibility: hidden;
}

.fomo-wrapper.visible {
  transform: translateY(0); /* Slide in from the bottom */
  visibility: visible;
}

.fomo-wrapper {
  border: 0.5px solid;
  z-index: 11;
  min-width: 36rem;
  max-width: 40rem;
  opacity: 1;
  visibility: visible;
  transition: transform 0.5s ease-in-out, visibility 0.5s ease-in-out;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 12rem;
  left: 0;
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
  margin: 1rem;

  .fomo-inner-wrapper {
    position: relative;

    .fomo-msg-wrapper {
      .product-title-overflow-hidden {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 34rem;
      }
    }
    .close-icon-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }
}
// FOMO product styling ends here
