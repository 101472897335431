.sample-icons {
    .tab-pane {
        overflow: hidden;
    }
}

.sample-icon-circle {
    margin-bottom: 2rem;

    i {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        font-size: 30px;
        background: $primary-color;
        color: #fff;
    }
}

.sample-icon {
    display: flex;
    align-items: center;
    line-height: 41px;

    span {
        font-size: 12px;
        white-space: nowrap;
    }

    i {
        display: inline-block;
        margin-right: 10px;
        width: 32px;
        font-size: 20px;
        text-align: center;
    }

    &:hover {
        color: $primary-color;
        background: #f2f3f5;

        i {
            font-size: 30px;
        }

        border-radius: 4px;
    }
}

.sample-icon-list {
    i {
        font-size: 22px;
    }
}

@include mq(xl) {
    .cols-xl-12>* {
        flex: 0 0 8.3333%;
        max-width: 8.3333%;
    }
}