.input-tel {
    display: flex;
    align-items: center;
    border: 1px solid #e1e1e1;
    border-radius: 0.3rem;
    padding-left: 0.5rem;
    overflow: hidden;

    .prefix {
        font-size: 1.3rem;
        padding-left: 1rem;
        color: #999;
        margin-top: 0.1rem;
    }

    input {
        flex-grow: 1;
        font-size: 14px;
        background: #fff;
        border: none;
        outline: none;
        padding: 0.5rem;
    }
}