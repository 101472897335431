/* -------------------------------------------
    Contact Page
---------------------------------------------- */

.bold {
  font-weight: 700;
}

.query-us p {
  font-weight: 500;
  font-size: 1.5rem;
}

.contact-us {
  background-color: white;
  .contact-us-title {
    margin: 32px 0px;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
  }
  .text-center {
    text-align: center;
  }
  .track-div {
    margin-bottom: 25px;
    @media (max-width: 650px) {
      margin-bottom: 10px;
    }
  }
  .track-section {
    color: #f27d1e;
    margin-top: 1px;
    margin-bottom: 8px;
    letter-spacing: 0.3px;
    font-weight: bolder;
    line-height: 1.4;
    font-size: 30px;
    text-align: center;
    @media (max-width: 750px) {
      font-size: 20px;
      margin-bottom: 0px;
    }
  }

  .page-content {
    color: black;
    .iframe-container {
      margin-top: 6px;
      margin-bottom: 6px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .contact-us-query {
    padding: 20px;
    display: flex;
    width: 100%;
    border-radius: 1px;
    background-color: #f4f6f8;
    @media (max-width: 750px) {
      flex-direction: column;
    }

    .first-section {
      width: 50%;
      @media (max-width: 750px) {
        width: 100%;
      }
    }
    .note-section {
      border: 1px solid #f27d1e;
      padding: 10px;
      border-radius: 5px;
      margin-top: 20px;
      @media (max-width: 750px) {
        width: 100%;
      }
      .heading {
        font-size: 25px;
        color: #a27550;
      }
      width: 50%;
    }
  }
  .div-query {
    font-size: 30px;
    letter-spacing: 0px;
    color: #a27550;
    font-weight: bold;
  }
  .span-query {
    color: #f27d1e;
    font-weight: bold;
    font-size: 32px;
    letter-spacing: 0px;
  }
  .btn-div {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }
  .btn {
    display: inline-block;
    background-color: #f27d1e;
    color: #fff;
    padding: 13px 20px;
    text-transform: none;
    font-size: medium;
    letter-spacing: 0.1em;
    border-radius: 5px;
    @media (max-width: 650px) {
      font-size: small;
      padding: 10px 18px;
    }
  }
  .contact-section {
    display: flex;
    gap: 24px;
    @media (max-width: 750px) {
      flex-direction: column;
    }

    .div-chat {
      @media (max-width: 750px) {
        font-size: 15px;
      }
      font-size: 30px;
      margin: 0;
      padding: 0;
      color: #a27550;
      font-weight: bold;
      margin-bottom: 4px;
      letter-spacing: 0px;
    }

    .div-section {
      @media (max-width: 750px) {
        font-size: 25px;
      }
      font-size: 40px;
      margin: 0;
      padding: 0;
      color: #f27d1e;
      font-weight: bold;
      line-height: 1.3;
      letter-spacing: 0px;
    }
  }
  .chat-img {
    // padding: 0px 12px;
    margin-bottom: 32px;
    @media (max-width: 750px) {
      width: 100%;
      margin: auto;
    }
  }

  .breadcrumb {
    padding: 1.2rem 0 1.1rem;

    li:last-child {
      color: $body-color;
    }

    li:not(:last-child) {
      a,
      &::after {
        opacity: 0.7;
      }
    }
  }

  .page-header {
    height: 30.2rem;
  }

  .page-title {
    font-size: 5rem;
  }

  .grey-section {
    background-color: #f7f7f9;
  }

  .store-section .title {
    font-size: 3rem;
  }
}

.contact-us .grey-section {
  padding: 2.5rem 3.2rem 1.4rem;

  h4 {
    font-size: 2rem;
    line-height: 1.1;
  }

  p {
    margin-bottom: 3.2rem;
    line-height: 1.72;
  }
}

.contact-section {
  form {
    h4 {
      margin-bottom: 0.3rem;
    }

    p {
      margin-bottom: 2.4rem;
      font-size: 1.3rem;
    }
  }

  .form-control {
    border-radius: 0.3rem;

    &::placeholder {
      color: #999;
    }
  }

  textarea {
    min-height: 15rem;
    padding: 1rem 2rem;
  }

  .btn i {
    font-size: 1.6rem;
    margin-left: 1rem;
    margin-bottom: 0.2rem;

    &::before {
      font-weight: 600;
    }
  }
}
