/* -------------------------------------------
    Categories
        Base
        - Default
        - Light Category
        - Icon Category
        - Ellipse Category
        - Group Category
            - Image
            - Icon
        - Banner Category (Masonry)
        - Overlay Category
        - Block Category
---------------------------------------------- */

// Base
.category {
  position: relative;
  font-size: 1rem;
  padding: 3rem;

  img {
    display: block;
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0.3rem;
  }

  .category-name {
    margin-bottom: 0;

    font: {
      size: 1.4rem;
      weight: 600;
    }

    line-height: 1.2;
    color: inherit;
    text-transform: capitalize;

    a {
      color: inherit;
      // &:hover {
      //     text-decoration: underline;
      // }
    }
  }

  .category-count {
    font-size: 1.2rem;
    line-height: 1.2;
  }
}

.story-category {
  .category-img {
    .category-media {
      border-radius: 50%;
    }
  }

  .category-name {
    text-align: center;
    margin-top: 0.6rem;
  }
}

.category-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: background 0.3s;
}

// Default
.category-default .category-content {
  background-color: $white-color;
}

.category-default,
.category-light {
  .category-name {
    margin-bottom: 0;
    color: $dark-color;
    letter-spacing: 0.025em;
  }
}

@keyframes slideUpShorter {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(0);
  }
}

// Ellipse Category
.ellipse-section .owl-stage-outer {
  margin: -2rem;
  padding: 2rem;
}

.category-ellipse {
  .category-media {
    border-radius: 50%;
    overflow: hidden;
    background: #fff;
    text-align: center;
  }

  .category-content {
    padding-top: 1rem;
  }

  .category-name {
    letter-spacing: 0.035em;
    color: inherit;

    &:hover {
      color: $primary-color;
    }
  }

  .category-count {
    text-transform: uppercase;
  }

  &:hover {
    img {
      transform: rotateZ(0) translateX(0);
    }
  }
}

.story-section {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  background-color: $background-color-1;
  color: $text-color-1;
}

.story-wrapper {
  display: flex;
  width: 95vw;
  touch-action: pan-x !important;
  overflow-y: scroll;

  .category-name {
    font-size: 1.2rem;
  }

  .category {
    min-width: 18vw;
    padding: 0 !important;
    margin: 0 0.5rem !important;
  }
}

.story-wrapper::-webkit-scrollbar {
  display: none;
}

.category-header {
  .description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.border-container {
  .owl-stage-outer {
    padding: 1px;
    margin: -1px;
  }
}

@include mq(xs, max) {
  .category-absolute .btn {
    line-height: 50px;
    padding: 0;
  }
}

@include mq(sm, max) {
  .category-spacing {
    padding: 0 !important;
  }
}
